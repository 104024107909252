import { createContext, useCallback, useContext, useMemo } from 'react'

import { theme } from '../../tailwind.config.js'
import { DEFAULT_THEME } from '../constants'
import { useUserAccountContext } from './userContext'

export type ThemeValues = DEFAULT_THEME | string

export interface ThemeColors {
  hex: {
    brand: string
    element: string
  }
  border: {
    brand: string
    inverse: string
  }
  shadow: {
    default: string
  }
  outline: {
    brand: string
  }
  placeholder: {
    inverse: string
  }
  background: {
    default: string
    inactive: string
    edit: string
    brand: string
    neutral: string
    danger: string
    gradient: string
    flowGradient: string
    element: string
    highlight: string
  }
  text: {
    default: string
    inverse: string
    brand: string
    danger: string
    inactive: string
    subtle: string
    subtlest: string
  }
  icon: {
    default: string
    inverse: string
    brand: string
    danger: string
    inactive: string
    highlight: string
  }
  elevation: {
    border: {
      default: string
      selected: string
      danger: string
      highlight: string
    }
    surface: {
      default: string
      secondary: string
      highlight: string
      sunken: string
    }
  }
}

interface ThemeContextType {
  activeTheme: ThemeValues
  setActiveTheme: (newTheme: string) => Promise<void>
  colors: ThemeColors
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

const getColors = (activeTheme: ThemeValues): ThemeColors => {
  switch (activeTheme) {
    case DEFAULT_THEME.Light:
      return {
        hex: {
          brand: theme.extend.colors['brand-light'],
          element: theme.extend.colors.k2.gray[25],
        },
        border: {
          brand: 'border-brand-light',
          inverse: 'border-black',
        },
        outline: {
          brand: 'outline-brand-light',
        },
        shadow: {
          default: 'shadow-k2-gray-25',
        },
        placeholder: {
          inverse: 'placeholder-black',
        },
        background: {
          default: 'bg-white',
          inactive: 'bg-neutral-400',
          edit: 'bg-k2-blue-400',
          brand: 'bg-brand-light',
          neutral: 'bg-k2-neutral-100/75',
          danger: 'bg-red-300',
          gradient: 'bg-gradient-to-b from-white to-gray-100',
          flowGradient:
            'var(--Color-Gradient-Light-Mode, radial-gradient(49.17% 106.53% at 102.44% 56.67%, rgba(207, 58, 138, 0.15) 0%, rgba(130, 62, 242, 0.15) 100%), radial-gradient(121.41% 163.55% at -3.41% 2.42%, rgba(1, 175, 213, 0.35) 0%, rgba(123, 128, 255, 0.35) 100%))',
          element: 'bg-k2-gray-25',
          highlight: 'bg-k2-blue-200',
        },
        text: {
          default: 'text-gray-900',
          inverse: 'text-white',
          brand: 'text-brand-light',
          danger: 'text-red-300',
          inactive: 'text-neutral-400',
          subtle: 'text-gray-700',
          subtlest: 'text-gray-400',
        },
        icon: {
          default: 'text-gray-900',
          inverse: 'text-white',
          brand: 'text-brand-light',
          danger: 'text-red-300',
          inactive: 'text-neutral-400',
          highlight: 'bg-k2-blue-200',
        },
        elevation: {
          border: {
            default: 'border-gray-200',
            selected: 'border-brand-light',
            danger: 'border-red-300',
            highlight: 'border-blue-200',
          },
          surface: {
            default: 'bg-gray-25',
            secondary: 'bg-k2-neutral-200/75',
            highlight: 'bg-blue-50',
            sunken: 'bg-gray-50',
          },
        },
      }
    case DEFAULT_THEME.Dark:
      return {
        hex: {
          brand: theme.extend.colors['brand-dark'],
          element: theme.extend.colors.neutral[75],
        },
        border: {
          brand: 'border-brand-dark',
          inverse: 'border-white',
        },
        shadow: {
          default: 'shadow-neutral-75',
        },
        outline: {
          brand: 'outline-brand-dark',
        },
        placeholder: {
          inverse: 'placeholder-white',
        },
        background: {
          default: 'bg-gray-900',
          inactive: 'bg-neutral-700',
          edit: 'bg-blue-500',
          brand: 'bg-brand-dark',
          neutral: 'bg-k2-neutral-700/75',
          danger: 'bg-red-400',
          gradient: 'bg-gradient-to-b from-gray-900 to-gray-800',
          flowGradient:
            'radial-gradient(63.53% 93.03% at 82.51% 30.8%, rgba(202, 162, 83, 0.04) 0%, rgba(76, 10, 54, 0.15) 100%), radial-gradient(177.83% 168.15% at 7.82% -10.71%, rgba(5, 50, 210, 0.59) 0%, rgba(58, 64, 119, 0.48) 64.23%, rgba(234, 189, 30, 0.60) 100%)',
          element: 'bg-neutral-75',
          highlight: 'bg-k2-primary-highlighted',
        },
        text: {
          default: 'text-white',
          inverse: 'text-gray-900',
          brand: 'text-brand-dark',
          danger: 'text-red-400',
          inactive: 'text-gray-500',
          subtle: 'text-gray-300',
          subtlest: 'text-gray-400',
        },
        icon: {
          default: 'text-white',
          inverse: 'text-gray-900',
          brand: 'text-brand-dark',
          danger: 'text-red-400',
          inactive: 'text-gray-500',
          highlight: 'text-shrek',
        },
        elevation: {
          border: {
            default: 'border-gray-700',
            selected: 'border-brand-dark',
            danger: 'border-red-400',
            highlight: 'border-yellow-200',
          },
          surface: {
            default: 'bg-gray-800',
            secondary: 'bg-k2-gray-900',
            highlight: 'bg-gray-700',
            sunken: 'bg-k2-gray-700',
          },
        },
      }
    default:
      return getColors(DEFAULT_THEME.Light) // Default to light theme colors
  }
}

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentUser, updateUserPreferences } = useUserAccountContext()

  const activeTheme = useMemo(
    () => currentUser?.preferences?.activeTheme || DEFAULT_THEME.Dark,
    [currentUser?.preferences?.activeTheme],
  )

  const setActiveTheme = useCallback(
    async (newTheme: string) => {
      try {
        await updateUserPreferences({ activeTheme: newTheme })
      } catch (error) {
        console.error('Error updating theme:', error)
        throw error
      }
    },
    [updateUserPreferences],
  )

  const colors = useMemo(() => getColors(activeTheme), [activeTheme])

  return (
    <ThemeContext.Provider value={{ activeTheme, setActiveTheme, colors }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}
