import { useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { MediaProgressOverlay } from './MediaProgressOverlay'
import { TEXT_URI_LIST } from '../../../constants'
import { usePresetContext, useThemeContext } from '../../../context'
import {
  useAnalytics,
  useNodeUtility,
  useCreateCollection,
} from '../../../hooks'
import { AddToCollectionIcon } from '../../../images/icons/AddToCollectionIcon'
import { AddToFlowIcon } from '../../../images/icons/AddToFlowIcon'
import { DownloadIcon } from '../../../images/icons/DownloadIcon'
import { EditIcon } from '../../../images/icons/EditIcon'
import { XMarkIcon2 } from '../../../images/icons/XMarkIcon2'
import { cn } from '../../../utils'
import {
  getProxiedR2FileUrl,
  getFileExtensionFromBlob,
} from '../../../utils/fileUtils'
import { Skeleton } from '../../loading'
import { MediaOverlayMenu } from '../Menus'
import { NodeToolbar, ToolbarButton } from '../NodeToolbar'
import { AnalyticsEvent, Media, MediaType, Status, Video } from '@/types'

export interface MediaDisplayProps {
  media: Media
  rotate?: number | string
  width: number | string
  height: number | string
  className?: string
  thumbnailClassName?: string
  nodeId?: string
  onCropperNodeChange?: () => void
  showNodeToolbar?: boolean
  showDownloadButton?: boolean
  isIntersecting?: boolean
  hideProgress?: boolean
}

export const MediaDisplay: React.FC<MediaDisplayProps> = ({
  media,
  rotate = 0,
  width,
  height,
  className,
  thumbnailClassName,
  nodeId,
  onCropperNodeChange: handleChangeToCropperNode,
  showNodeToolbar = false,
  showDownloadButton = false,
  isIntersecting = false,
  hideProgress = false,
}) => {
  const { trackEvent } = useAnalytics()
  const { deleteNodeById } = useNodeUtility()
  const { openPresetMenu } = usePresetContext()
  const { createCollectionFromSingleNode } = useCreateCollection()
  const { colors } = useThemeContext()

  const [isHovering, setIsHovering] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const isLoading = media.status === Status.Pending

  const handleMouseEnter = () => {
    setIsHovering(true)
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error('Error playing video:', error)
      })
    }
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }

  const handleVideoDragStart = (event: React.DragEvent<HTMLVideoElement>) => {
    event.dataTransfer.setData(TEXT_URI_LIST, media.source)
  }

  const toggleSound = () => {
    setIsMuted(!isMuted)
  }

  const handleDownload = async () => {
    try {
      const response = await fetch(getProxiedR2FileUrl(media.source))
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      let extension = await getFileExtensionFromBlob(blob)
      if (!extension) {
        extension = media.type === MediaType.Video ? 'mp4' : 'png'
      }

      link.href = url
      link.download = `${nodeId || media.mediaId}.${extension}`
      link.click()
      window.URL.revokeObjectURL(url)

      trackEvent(AnalyticsEvent.MediaDownloaded, {
        mediaId: media.mediaId,
        mediaType: media.type,
        fileSize: blob.size,
      })
    } catch (error) {
      console.error('Error downloading media:', error)
      toast('Error downloading media')
    }
  }

  const VideoThumbnail = ({ media }: { media: Video }) => (
    <>
      {(media as Video).thumbnailSource && (
        <img
          src={media.thumbnailSource}
          alt='Video thumbnail'
          style={{ transform: `rotate(${rotate}deg)` }}
          className={cn(
            'object-cover rounded-2xl transition-all ease-in-out duration-300',
            'shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]',
            thumbnailClassName,
            isHovering && !isLoading ? 'animate-fade-out' : 'opacity-100',
            isLoading && 'blur-xs',
            !!media.source && 'absolute top-0 left-0',
          )}
        />
      )}
      {media.status === Status.Pending && (
        <MediaProgressOverlay
          progress={media.progress}
          hideProgress={hideProgress}
        />
      )}
    </>
  )

  const isEditable =
    !nodeId?.includes('cropped') && media.type === MediaType.Image

  const toolbarButtons: ToolbarButton[] = [
    {
      icon: AddToCollectionIcon,
      onClick: () => createCollectionFromSingleNode(nodeId),
      className: colors.text.default,
      title: 'Create collection from selection',
    },
    {
      icon: AddToFlowIcon,
      onClick: () => openPresetMenu(media),
      title: 'use in flow',
      disabled: !media.type,
      className: colors.text.default,
    },
    ...(isEditable && handleChangeToCropperNode
      ? [
          {
            icon: EditIcon,
            onClick: handleChangeToCropperNode,
            title: 'media edit',
            className: colors.text.default,
          },
        ]
      : []),
    {
      icon: DownloadIcon,
      onClick: handleDownload,
      title: 'download',
      size: 27,
      className: colors.text.default,
    },
    {
      icon: XMarkIcon2,
      onClick: () => deleteNodeById(nodeId),
      className: 'text-alarm',
      title: 'remove from canvas',
      size: 28,
    },
  ]

  const wrapperStyle = {
    aspectRatio: `${width}/${height}`,
  }

  return (
    <div
      className={cn('relative', className)}
      style={wrapperStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading && <Skeleton />}
      <div className='absolute inset-0 rounded-2xl'>
        {media.type === MediaType.Video ? (
          <>
            <VideoThumbnail media={media as Video} />
            {!!media.source && (
              <video
                id={media.mediaId}
                ref={videoRef}
                src={media.source}
                loop={true}
                controls={false}
                draggable
                onDragStart={handleVideoDragStart}
                style={{ transform: `rotate(${rotate}deg)` }}
                className={cn(
                  'object-cover w-full h-full rounded-2xl transition-all ease-in-out duration-300 opacity-0',
                  'shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]',
                  thumbnailClassName,
                  {
                    'opacity-100 scale-[1.02]': isHovering && !isLoading,
                    'opacity-100': !(media as Video).thumbnailSource,
                  },
                )}
                preload='metadata'
                muted={isMuted}
              />
            )}
          </>
        ) : (
          !!media.source && (
            <div className='w-full h-full'>
              <img
                src={media.source}
                alt='Media'
                style={{
                  transform: `rotate(${rotate}deg)`,
                }}
                className={cn(
                  'object-cover w-full h-full rounded-2xl transform relative',
                  thumbnailClassName,
                  'shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]',
                )}
              />

              {isIntersecting && (
                <div
                  className={cn('absolute inset-0 rounded-2xl', {
                    'bg-gradient-to-t from-[rgba(232,255,140,0.50)] to-[rgba(232,255,140,0.50)]':
                      isIntersecting,
                  })}
                />
              )}
            </div>
          )
        )}
        <div
          className={cn(
            'absolute inset-0 transition-opacity duration-300 pointer-events-none',
            'before:content-[""] before:absolute before:left-0 before:right-0 before:-top-2 before:h-2 before:pointer-events-auto',
            isHovering ? 'opacity-100' : 'opacity-0',
          )}
        >
          {!isLoading && (
            <>
              {/* Note: NodeToolbar should only be used on canvas */}
              {showNodeToolbar && nodeId && (
                <div className='pointer-events-auto absolute left-1/2 -translate-x-1/2 -top-12 transition-opacity duration-300'>
                  <NodeToolbar
                    buttons={toolbarButtons}
                    scaleOriginClassName='origin-bottom'
                  />
                </div>
              )}
              <MediaOverlayMenu
                mediaType={media.type}
                nodeId={nodeId}
                onDownload={handleDownload}
                isMuted={isMuted}
                showDownloadButton={showDownloadButton}
                toggleSound={toggleSound}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
