/* eslint-disable */
// TODO: Fix this file and Split into subtypes by props
import { useEffect, useMemo, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import { toast } from 'react-toastify'
import CurrencyFormat from 'react-currency-format'
import Lottie from 'lottie-react'

import AskForUpgradeOrDowngradeModal from './AskForUpgradeOrDowngradeModal'
import { Modal } from './Modal'
import http from '../services/HttpService'
import { useUserAccountContext } from '../context/userContext'
import Tooltip from './Tooltip'
import { Alert } from '../components/Alert'
import { useReCaptcha } from '../hooks/useReCaptcha'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import { getCurrentUser } from '../services/AuthService'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Logtail } from '@logtail/browser'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

const credit = ({ isOpen, onClose, amount = 0 }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const mixpanel = useMixpanel()
  const { currentUser, setCurrentUser } = useUserAccountContext()

  const createCreditsPurchaseSession = async () => {
    setIsSubmitting(true)
    // create subscription session with pricing interval
    const response = await http.get('/api/create_credits_purchase_session', {
      params: {
        quantity: amount / 100,
      },
    })

    mixpanel.track('Credits Purchase Session Created', {
      quantity: amount,
    })

    trackEvent(
      mixpanel,
      currentUser,
      MIXPANEL_EVENTS.CREDITS_PURCHASE_FLOW_STARTED,
      {
        credits_amount: amount,
      },
    )

    window.location.href = response.data
  }

  useEffect(() => {
    if (isOpen) {
      createCreditsPurchaseSession()
    }
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      setOpen={isSubmitting ? () => {} : onClose}
      persistent={isSubmitting}
    >
      <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
        {/* <p className='text-tertiary/80'>
          You're about to reuse the settings used by the creator of this video.
        </p> */}
        <div className='mt-4 flex flex-col items-center justify-end gap-2'>
          {isSubmitting ? (
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          ) : (
            <>
              <button
                onClick={() => createCreditsPurchaseSession()}
                className='uppercase mt-8 block w-52 rounded-full border border-gray-800 radial-gradient button-shadow py-2 text-center text-sm font-semibold text-gray-900 hover:bg-white'
              >
                BUY
              </button>
              {/* <PaypalBuyButton clientId={process.env.PAYPAL_CLIENT_ID} amount={(amount / 100) * 1.5} /> */}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

const subscribe = ({ isOpen, onClose, onOpen, tier }) => {
  const mixpanel = useMixpanel()
  const reCaptcha = useReCaptcha()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [upgradeplan, setUpgradeplan] = useState(false)
  const [subscriptionCurrencyValue, setSubscriptionCurrencyValue] =
    useState('$')
  const [isUpgradingCurrentSubscription, setIsUpgradingCurrentSubscription] =
    useState(null)
  const [subscriptionUpgradeChargeValue, setSubscriptionUpgradeChargeValue] =
    useState(null)
  const [subscriptionUpgradeRefundValue, setSubscriptionUpgradeRefundValue] =
    useState(null)
  const [creditsToBeAdded, setCreditsToBeAdded] = useState(null)
  const [creditsToBeDeducted, setCreditsToBeDeducted] = useState(null)
  const [creditsAfterUpgrade, setCreditsAfterUpgrade] = useState(null)
  const [creditsAfterDowngrade, setCreditsAfterDowngrade] = useState(null)
  const [
    isAskForUpgradeOrDowngradeModalOpen,
    setIsAskForUpgradeOrDowngradeModalOpen,
  ] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const isSubscribed = currentUser?.subscriptionType === 'Standard'
  const paymentGateway = currentUser?.paymentGateway
  const usePaymentGateway = currentUser?.usePaymentGateway
  const subscriptionInterval = currentUser?.subscriptionInterval
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useState(false)
  const [inputCouponValue, setInputCouponValue] = useState('')
  const [coupon, setCoupon] = useState(null)

  const tierDescription = useMemo(() => {
    if (tier && tier.name) {
      return tier.name
        .replace('_tier', '')
        .split('_')
        .map((s) => s[0].toUpperCase() + s.substring(1))
        .join(' ')
    }

    return null
  }, [tier])

  const fetchSubscriptionUpgradeChargeValue = async () => {
    try {
      setIsLoading(true)
      let tempCoupon

      if (inputCouponValue != null && inputCouponValue != '') {
        tempCoupon = await getCoupon()

        if (tempCoupon != null) {
          if (tempCoupon.promotionCode != null) {
            setCoupon(tempCoupon)
            setCouponError(false)
            setCouponSuccess(true)
          } else {
            setCouponError(true)
            setCouponSuccess(false)
            setIsLoading(false)
            return
          }
        } else {
          setCouponError(true)
          setCouponSuccess(false)
          setIsLoading(false)
          return
        }
      }

      const response = await http.get('/api/get_subscription_upgrade_value', {
        params: {
          tier: tier.name,
          coupon: tempCoupon && tempCoupon.coupon ? tempCoupon.coupon : null,
        },
      })

      mixpanel.track('subscription_flow_started', {
        distinct_id: currentUser?.distinctId,
        subscription_tier: tier.name,
        user_email: currentUser?.email,
        subscription_coupon_code:
          tempCoupon && tempCoupon.coupon ? tempCoupon.coupon : null,
      })
      trackEvent(
        mixpanel,
        currentUser,
        MIXPANEL_EVENTS.SUBSCRIPTION_FLOW_STARTED,
        {
          subscription_tier: tier.name,
          subscription_coupon_code:
            tempCoupon && tempCoupon.coupon ? tempCoupon.coupon : null,
        },
      )

      setIsLoading(false)

      if (response.data.currencyCode !== 'USD') {
        setSubscriptionCurrencyValue(
          response.data.currencyCode +
            ' ' +
            getSymbolFromCurrency(response.data.currencyCode),
        )
      }

      setSubscriptionUpgradeChargeValue(response.data.invoiceAmount)
      setSubscriptionUpgradeRefundValue(response.data.refundAmount)
      setIsUpgradingCurrentSubscription(response.data.isUpgrade)
      setCreditsToBeAdded(response.data.creditsToBeAdded)
      setCreditsToBeDeducted(response.data.creditsToBeDeducted)
      setCreditsAfterUpgrade(response.data.creditsAfterUpgrade)
      setCreditsAfterDowngrade(response.data.creditsAfterDowngrade)
    } catch (err) {
      console.log(err)
      toast.error(err.response?.data || 'Something went wrong.')
      setIsLoading(false)
      onClose()
    }
  }

  const openAskForUpgradeOrDowngradeModal = async () => {
    onClose()

    setTimeout(() => {
      mixpanel.track('subscription_flow_started', {
        distinct_id: currentUser?.distinctId,
        subscription_tier: tier.name,
        user_email: currentUser?.email,
      })
      trackEvent(
        mixpanel,
        currentUser,
        MIXPANEL_EVENTS.SUBSCRIPTION_FLOW_STARTED,
        {
          subscription_tier: tier.name,
        },
      )

      setIsAskForUpgradeOrDowngradeModalOpen(true)
    }, 200)
  }

  const onUpgradeOrDowngrade = async () => {
    if (paymentGateway === 'stripe') {
      createSubscriptionSession()

      setIsAskForUpgradeOrDowngradeModalOpen(false)

      setTimeout(() => {
        onOpen()
      }, 200)
    } else {
      setIsSubmitting(true)

      try {
        const res = await http.post('/api/update_subscription', {
          tier: tier.name,
          // sending only the coupon id
          coupon: coupon && coupon.coupon ? coupon.coupon : null,
        })

        if (res.status !== 200) {
          throw new Error(res.data || 'Something went wrong.')
        }

        const response = await getCurrentUser()

        setIsSubmitting(false)
        setIsAskForUpgradeOrDowngradeModalOpen(false)
        setCurrentUser((currentUser) => ({
          ...currentUser,
          ...response.data.user,
        }))
      } catch (err) {
        console.log(err)
        toast.error(err.response?.data || 'Something went wrong.')
        setIsSubmitting(false)
        setTimeout(() => {
          setIsAskForUpgradeOrDowngradeModalOpen(false)
        }, 200)
      }
    }
  }

  const subscribeToPaddle = async () => {
    setIsSubmitting(true)

    logtail.info(
      `${currentUser?.distinctId} felog paymentModal.js calling subscribeToPaddle()`,
      currentUser,
    )
    logtail.flush()

    let paddleCustomer = currentUser?.paddleCustomerId
      ? { id: currentUser?.paddleCustomerId }
      : { email: currentUser?.email }

    mixpanel.track('subscription_flow_started', {
      distinct_id: currentUser?.distinctId,
      subscription_tier: tier.name,
      user_email: currentUser?.email,
    })
    trackEvent(
      mixpanel,
      currentUser,
      MIXPANEL_EVENTS.SUBSCRIPTION_FLOW_STARTED,
      {
        subscription_tier: tier.name,
      },
    )

    // Fix for legacy users who don't have a paddle customer id
    // &&
    // always check users data from DB in case previous transaction failed
    if (!currentUser?.usePaymentGateway === 'stripe') {
      await http.get('/api/auth/paddle/verify')
    }

    const response = await getCurrentUser()

    setCurrentUser((currentUser) => ({
      ...currentUser,
      ...response.data.user,
    }))

    const userResponse = response?.data?.user

    logtail.info(
      `${currentUser?.distinctId} felog paymentModal.js subscribeToPaddle() get current user userResponse`,
      userResponse,
    )
    logtail.flush()

    // after authorized region verification, check usePaymentGateway if it was set to stripe
    if (userResponse?.usePaymentGateway === 'stripe') {
      createSubscriptionSession()
      return
    }

    // if user has an active subscription but is set to cancel their subscription on the next renewal date:
    // Tell the user to reactivate their subscription before changing their subscription
    if (
      userResponse?.subscriptionStatus &&
      userResponse?.subscriptionCancelAtPeriodEndDate
    ) {
      logtail.info(
        `${currentUser?.distinctId} felog paymentModal.js subscribeToPaddle() user on an cancelled active subscription -- force exit payment flow`,
        currentUser,
      )
      logtail.flush()
      toast.error(
        'Please reactivate your subscription before changing it. Visit your Account page to reactivate your subscription.',
      )
      onClose()
      return
    }

    // if user is already subscribed, don't show the subscription modal, reload to update the modals
    if (userResponse?.subscriptionStatus) {
      logtail.info(
        `${currentUser?.distinctId} felog paymentModal.js subscribeToPaddle() user already subscribed -- force exit payment flow`,
        currentUser,
      )
      logtail.flush()

      window.location.reload()
      return
    }

    if (userResponse?.paddleCustomerId) {
      paddleCustomer = { id: userResponse?.paddleCustomerId }
    }

    window.localStorage.setItem('USERCHOSENTIER', JSON.stringify(tier))

    logtail.info(
      `${currentUser?.distinctId} felog paymentModal.js subscribeToPaddle() -- opening Paddle iframe`,
      currentUser,
    )
    logtail.flush()

    window.Paddle.Checkout.open({
      settings: {
        theme: 'dark',
        allowLogout: false,
      },
      customer: paddleCustomer,
      items: [
        {
          priceId: tier.id,
        },
      ],
    })
  }

  const createSubscriptionSession = async (optionalPaddlePrice) => {
    const finalTier = optionalPaddlePrice ? optionalPaddlePrice : tier
    setIsSubmitting(true)

    const reCaptchaToken = await getReCaptchaToken()

    try {
      if (!reCaptchaToken) throw new Error('Captcha error')

      // create subscription session with pricing interval
      const response = await http.post('/api/create_subscription_session', {
        reCaptchaToken,
        tier: finalTier,
        couponValue: coupon != null ? coupon : null,
      })
      // mixpanel.track('Subscription Session Created', {
      //   finalTier,
      // })

      if (response.data) {
        window.location.href = response.data
      } else {
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch (err) {
      setTimeout(() => {
        if (currentUser?.subscriptionType === 'Standard') {
          setIsSubmitting(false)
        } else {
          onClose(false)
        }
      }, 500)

      if (err.response) {
        toast.error(
          err.response.data?.message ||
            err.response.data?.error ||
            err.response.data,
        )
      } else {
        toast.error(err.message)
      }
    }
  }

  const getCoupon = async () => {
    const response = await http.post('/api/get_coupon_info', {
      couponValue: inputCouponValue,
    })

    let couponData = {
      coupon: null,
      promotionCode: null,
    }
    if (response.data.data.length > 0) {
      const responseData = response.data.data[0]

      couponData.promotionCode = responseData

      if (currentUser?.paymentGateway === 'stripe') {
        couponData.coupon = responseData.coupon
        if (couponData.promotionCode.max_redemptions != null) {
          if (
            couponData.promotionCode.max_redemptions <
            couponData.promotionCode.times_redeemed
          ) {
            couponData = null
          }
        }
      } else {
        couponData.coupon = responseData.id
        if (couponData.promotionCode.usage_limit != null) {
          if (
            couponData.promotionCode.usage_limit <
            couponData.promotionCode.times_used
          ) {
            couponData = null
          }
        }
      }
    }

    return couponData
  }

  const getReCaptchaToken = async () => {
    try {
      return await reCaptcha.getToken()
    } catch (err) {
      console.error(err)
      setShowAlert(true)
      setAlertMessage(err.message || 'Captcha error')
      return null
    }
  }

  // let planId = process.env.PAYPAL_YEARLY_PLAN

  // if (type === 'yearly') {
  //   planId = process.env.PAYPAL_YEARLY_PLAN
  // }
  // else {
  //   planId = process.env.PAYPAL_MONTHLY_PLAN
  // }

  useEffect(() => {
    if (!isOpen) return

    // if is subscribed and not cancel at period end
    if (isSubscribed && !currentUser?.subscriptionCancelAtPeriodEndDate) {
      logtail.info(
        `${currentUser?.distinctId} felog paymentModal.js useEffect[isOpen] sub upgrade - open API modal`,
        currentUser,
      )
      logtail.flush()
      fetchSubscriptionUpgradeChargeValue()
      return
    }

    logtail.info(
      `${currentUser?.distinctId} felog paymentModal.js useEffect[isOpen] new sub - open Paddle iframe`,
      currentUser,
    )

    if (
      !paymentGateway ||
      paymentGateway === 'paddle' ||
      usePaymentGateway === 'paddle'
    ) {
      subscribeToPaddle()
      return
    }

    // always default to stripe on any issues
    createSubscriptionSession()
  }, [isOpen])

  useEffect(() => {
    if (!isSubscribed && subscriptionInterval !== 'month') {
      setIsLoading(false)
    }

    // .ru email domains are not allowed so they don't get prefilled.
    // if the email is different from the current user's email, redirect
    window.addEventListener('paddleCheckoutVerify', async (data) => {
      const paddleData = data?.detail?.data
      if (paddleData?.customer?.email !== currentUser?.email) {
        const paddlePriceId = paddleData?.items[0]?.price_id

        window.Paddle.Checkout.close()
        createSubscriptionSession(paddlePriceId)
      }
    })

    // for any failed paddle checkout, consider it as denied/sanctioned so redirect
    window.addEventListener('paddleCheckoutFailed', async (data) => {
      const paddlePriceId =
        JSON.parse(window.localStorage.getItem('USERCHOSENTIER')) ?? null

      window.Paddle.Checkout.close()

      if (paddlePriceId) {
        createSubscriptionSession(paddlePriceId)
      }
    })
  }, [])

  function handleClose() {
    onClose()
    setIsSubmitting(false)
    setInputCouponValue('')
    setCouponError(false)
    setCouponSuccess(false)
  }

  return (
    <>
      <Modal
        open={isOpen}
        setOpen={isLoading || isSubmitting ? () => {} : handleClose}
        persistent={isLoading || isSubmitting}
      >
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          {showAlert && (
            <div className='mb-4'>
              <Alert
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                type='alert'
              />
            </div>
          )}

          {/* <p className='text-tertiary/80'>
            You're about to reuse the settings used by the creator of this video.
          </p> */}
          <div className='mt-4 flex flex-col items-center justify-end gap-2'>
            {isLoading || isSubmitting ? (
              <Lottie
                animationData={LoadingWhite}
                loop={true}
                className='w-16 h-16'
              />
            ) : (
              <div className='w-full space-y-8'>
                <p className='text-2xl sm:text-5xl text-tertiary'>
                  {tierDescription}
                </p>
                <div className='text-tertiary/80'>
                  {isUpgradingCurrentSubscription ? (
                    <>
                      You are about to upgrade your plan.
                      <div className='flex justify-between py-3 mt-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Total (taxes included)</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          <CurrencyFormat
                            value={subscriptionUpgradeChargeValue / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={subscriptionCurrencyValue}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Refund amount</strong>
                          {subscriptionUpgradeRefundValue == 0 && (
                            <Tooltip
                              className='inline-block'
                              content={`You don't have enough credits to be refunded.`}
                            >
                              <InformationCircleIcon className='w-5 h-5 inline-block ml-1 mb-1 text-tertiary/80' />
                            </Tooltip>
                          )}
                        </p>
                        <p className='text-tertiary/80'>
                          {subscriptionUpgradeRefundValue > 0 ? (
                            <CurrencyFormat
                              value={subscriptionUpgradeRefundValue / 100}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={subscriptionCurrencyValue}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Current credits</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          {currentUser?.credits}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits to be deducted</strong>
                          {creditsToBeDeducted > 0 && (
                            <Tooltip
                              className='inline-block'
                              content='The credits you received from your previous upgrade will be deducted.'
                            >
                              <InformationCircleIcon className='w-5 h-5 inline-block ml-1 mb-1 text-tertiary/80' />
                            </Tooltip>
                          )}
                        </p>
                        <p className='text-tertiary/80'>
                          {creditsToBeDeducted > 0
                            ? `-${creditsToBeDeducted}`
                            : '-'}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits to be added</strong>
                        </p>
                        <p className='text-tertiary/80'>+{creditsToBeAdded}</p>
                      </div>
                      <div className='flex justify-between py-3 mb-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits after upgrade</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          {creditsAfterUpgrade}
                        </p>
                      </div>
                      {couponError && (
                        <label className='text-red-500'>
                          Coupon is invalid
                        </label>
                      )}
                      <div className='flex gap-4 my-2 content-center'>
                        <input
                          type='text'
                          placeholder={
                            couponError
                              ? 'Coupon code is invalid'
                              : 'Add your coupon code'
                          }
                          onChange={(e) => {
                            setCouponError(false)
                            setInputCouponValue(e.target.value)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              fetchSubscriptionUpgradeChargeValue()
                            }
                          }}
                          className={`flex w-full mb-3 py-3 px-2 text-tertiary/80
                            border  rounded-xl bg-[#0b0b0b]
                            ${
                              couponError
                                ? 'placeholder-red-500 border-red-500'
                                : ' border-[#1E1F1E]'
                            }
                            ${
                              couponSuccess
                                ? ' border-primary'
                                : ' border-[#1E1F1E]'
                            }
                          `}
                          value={inputCouponValue}
                        />

                        <div className='mt-3'>
                          <button
                            onClick={fetchSubscriptionUpgradeChargeValue}
                            className={
                              'e-link text-primary hover:underline self-center'
                            }
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      You are about to downgrade your plan.
                      <div className='flex justify-between py-3 mt-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Total (taxes included)</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          <CurrencyFormat
                            value={subscriptionUpgradeChargeValue / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={subscriptionCurrencyValue}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Refund amount</strong>
                          {subscriptionUpgradeRefundValue == 0 && (
                            <Tooltip
                              className='inline-block'
                              content={`You don't have enough credits to be refunded.`}
                            >
                              <InformationCircleIcon className='w-5 h-5 inline-block ml-1 mb-1 text-tertiary/80' />
                            </Tooltip>
                          )}
                        </p>
                        <p className='text-tertiary/80'>
                          {subscriptionUpgradeRefundValue > 0 ? (
                            <CurrencyFormat
                              value={subscriptionUpgradeRefundValue / 100}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={subscriptionCurrencyValue}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Current credits</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          {currentUser?.credits}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits to be deducted</strong>
                          {creditsToBeDeducted > 0 && (
                            <Tooltip
                              className='inline-block'
                              content='The credits you received from your previous upgrade will be deducted proportionally from the refund amount.'
                            >
                              <InformationCircleIcon className='w-5 h-5 inline-block ml-1 mb-1 text-tertiary/80' />
                            </Tooltip>
                          )}
                        </p>
                        <p className='text-tertiary/80'>
                          {creditsToBeDeducted > 0
                            ? `-${creditsToBeDeducted}`
                            : '-'}
                        </p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits to be added</strong>
                        </p>
                        <p className='text-tertiary/80'>+{creditsToBeAdded}</p>
                      </div>
                      <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                        <p className='text-tertiary/80'>
                          <strong>Credits after downgrade</strong>
                        </p>
                        <p className='text-tertiary/80'>
                          {creditsAfterDowngrade}
                        </p>
                      </div>
                      {couponError && (
                        <label className='text-red-500'>
                          Coupon is invalid
                        </label>
                      )}
                      <div className='flex gap-4 my-2 content-center'>
                        <input
                          type='text'
                          placeholder={
                            couponError
                              ? 'Coupon code is invalid'
                              : 'Add your coupon code'
                          }
                          onChange={(e) => {
                            setCouponError(false)
                            setInputCouponValue(e.target.value)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              fetchSubscriptionUpgradeChargeValue()
                            }
                          }}
                          className={`flex w-full mb-3 py-3 px-2 text-tertiary/80
                            border  rounded-xl bg-[#0b0b0b]
                            ${
                              couponError
                                ? 'placeholder-red-500 border-red-500'
                                : ' border-[#1E1F1E]'
                            }
                            ${
                              couponSuccess
                                ? ' border-primary'
                                : ' border-[#1E1F1E]'
                            }
                          `}
                          value={inputCouponValue}
                        />

                        <div className='mt-3'>
                          <button
                            onClick={fetchSubscriptionUpgradeChargeValue}
                            className={
                              'e-link text-primary hover:underline self-center'
                            }
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='text-center'>
                  <button
                    onClick={openAskForUpgradeOrDowngradeModal}
                    className='w-full rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
                  >
                    {isUpgradingCurrentSubscription
                      ? 'UPGRADE PLAN'
                      : 'DOWNGRADE PLAN'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <AskForUpgradeOrDowngradeModal
        isOpen={isAskForUpgradeOrDowngradeModalOpen}
        onClose={setIsAskForUpgradeOrDowngradeModalOpen}
        isSubmitting={isSubmitting}
        onUpgradeOrDowngrade={onUpgradeOrDowngrade}
        tierDescription={tierDescription}
        subscriptionCurrencyValue={subscriptionCurrencyValue}
        subscriptionUpgradeChargeValue={subscriptionUpgradeChargeValue}
        subscriptionUpgradeRefundValue={subscriptionUpgradeRefundValue}
        isUpgrade={isUpgradingCurrentSubscription}
      />

      {reCaptcha.element}
    </>
  )
}

const pack = ({ isOpen, onClose, pack }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const mixpanel = useMixpanel()
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useState(false)
  const [inputCouponValue, setInputCouponValue] = useState('')
  // const [coupon, setCoupon] = useState(null)

  const createCreditsPurchaseSession = async () => {
    setIsSubmitting(true)

    try {
      const response = await http.get('/api/create_pack_session', {
        params: {
          pack: pack.id,
        },
      })

      mixpanel.track('Pack Session Created', {
        pack: pack.id,
      })

      window.location.href = response.data
    } catch (err) {
      // setIsSubmitting(false)
      setTimeout(() => {
        onClose(false)
      }, 500)

      if (err.response) {
        toast.error(
          err.response.data?.message ||
            err.response.data?.error ||
            err.response.data,
        )
      } else {
        toast.error(err.message)
      }
    }
  }

  const purchasePack = async () => {
    setIsSubmitting(true)

    try {
      await http.get('/api/purchase_pack', {
        params: {
          pack: pack.id,
        },
      })

      const response = await getCurrentUser()

      setIsSubmitting(false)
      onClose(false)
      setCurrentUser((currentUser) => ({
        ...currentUser,
        ...response.data.user,
      }))

      toast.success('Your credit pack has been purchased successfully')
    } catch (err) {
      // setIsSubmitting(false)
      setTimeout(() => {
        setIsSubmitting(false)
        onClose(false)
      }, 500)

      if (err.response) {
        toast.error(
          err.response.data?.message ||
            err.response.data?.error ||
            err.response.data,
        )
      } else {
        toast.error(err.message)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false)
    }
  }, [isOpen])

  function handleClose() {
    onClose()
    setInputCouponValue('')
    setCouponError(false)
    setCouponSuccess(false)
  }

  return (
    <Modal
      open={isOpen}
      setOpen={isLoading || isSubmitting ? () => {} : handleClose}
      persistent={isLoading || isSubmitting}
    >
      <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
        {/* {showAlert && (
          <div className='mb-4'>
            <Alert
              open={showAlert}
              setOpen={setShowAlert}
              message={alertMessage}
              type='alert'
            />
          </div>
        )} */}

        {/* <p className='text-tertiary/80'>
          You're about to reuse the settings used by the creator of this video.
        </p> */}
        <div className='mt-4 flex flex-col items-center justify-end gap-2'>
          {isLoading || isSubmitting ? (
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          ) : (
            <div className='w-full space-y-8'>
              <div className='text-tertiary/80'>
                <>
                  You are about to purchase additional credits.
                  <div className='flex justify-between py-3 mt-3 border-b border-[#1E1F1E]'>
                    <p className='text-tertiary/80'>
                      <strong>Invoice amount</strong>
                    </p>
                    <p className='text-tertiary/80'>
                      <CurrencyFormat
                        value={pack.price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </p>
                  </div>
                  <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                    <p className='text-tertiary/80'>
                      <strong>Current credits</strong>
                    </p>
                    <p className='text-tertiary/80'>{currentUser?.credits}</p>
                  </div>
                  <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                    <p className='text-tertiary/80'>
                      <strong>Credits to be added</strong>
                    </p>
                    <p className='text-tertiary/80'>+{pack.amount}</p>
                  </div>
                  <div className='flex justify-between py-3 mb-3 border-b border-[#1E1F1E]'>
                    <p className='text-tertiary/80'>
                      <strong>Credits after upgrade</strong>
                    </p>
                    <p className='text-tertiary/80'>
                      {currentUser?.credits + pack.amount}
                    </p>
                  </div>
                  {couponError && (
                    <label className='text-red-500'>Coupon is invalid</label>
                  )}
                  {/* <div className='flex gap-4 my-2 content-center'>
                    <input
                      type='text'
                      placeholder={couponError ? 'Coupon code is invalid' : 'Add your coupon code'}
                      onChange={(e) => {
                        setCouponError(false)
                        setInputCouponValue(e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          fetchSubscriptionUpgradeChargeValue()
                        }
                      }}
                      className={`flex w-full mb-3 py-3 px-2 text-tertiary/80
                        border  rounded-xl bg-[#0b0b0b]
                        ${couponError ? 'placeholder-red-500 border-red-500' : ' border-[#1E1F1E]'}
                        ${couponSuccess ? ' border-primary' : ' border-[#1E1F1E]'}
                      `}
                      value={inputCouponValue}
                    />

                    <div className='mt-3'>
                      <button
                        onClick={fetchSubscriptionUpgradeChargeValue}
                        className={'e-link text-primary hover:underline self-center'}
                      >Apply</button>
                    </div>
                  </div> */}
                </>
              </div>
              <div className='text-center'>
                <button
                  onClick={
                    currentUser?.paymentGateway === 'stripe'
                      ? createCreditsPurchaseSession()
                      : purchasePack
                  }
                  className='w-full rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
                >
                  BUY
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
    // <Modal open={isOpen} setOpen={isSubmitting ? () => { } : onClose} persistent={isSubmitting}>
    //   <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
    //     {/* <p className='text-tertiary/80'>
    //       You're about to reuse the settings used by the creator of this video.
    //     </p> */}
    //     <div className='mt-4 flex flex-col items-center justify-end gap-2'>
    //       {isSubmitting ? (
    //         <Lottie animationData={LoadingWhite} loop={true} className='w-16 h-16'/>
    //       ) : (
    //         <>
    //           <button
    //             onClick={() => createCreditsPurchaseSession()}
    //             className='uppercase mt-8 block w-52 rounded-full border border-gray-800 radial-gradient button-shadow py-2 text-center text-sm font-semibold text-gray-900 hover:bg-white'
    //           >
    //             BUY
    //           </button>
    //           {/* <PaypalBuyButton clientId={process.env.PAYPAL_CLIENT_ID} amount={pack.price} /> */}
    //         </>
    //       )}
    //     </div>
    //   </div>
    // </Modal>
  )
}

export default {
  credit,
  subscribe,
  pack,
}
