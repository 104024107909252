import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Logtail } from '@logtail/browser'
import classNames from 'classnames'
import Lottie from 'lottie-react'
import moment from 'moment'
import { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'

import Footer from '../components/Footer'
import { Modal } from '../components/Modal'
import Navbar from '../components/Navbar'
import PaymentModal from '../components/paymentModal'
import PricingBottomItem from '../components/pricing/PricingBottomItem'
import Tooltip from '../components/Tooltip'
import { useUserAccountContext } from '../context/userContext'
import InfoIcon from '../images/Info.svg'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import { getCurrentUser } from '../services/AuthService'
import http from '../services/HttpService'
import { CREDITS } from '../utils/constants'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

export default function Pricing() {
  const [pricingInterval, setPricingInterval] = useState('yearly')

  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [amount, setAmount] = useState(0)
  const [selectedTier, setSelectedTier] = useState(null)
  const { currentUser, setCurrentUser, isAuthenticated } =
    useUserAccountContext()
  const isSubscribed = currentUser?.subscriptionType === 'Standard'
  const subscriptionInterval = currentUser?.subscriptionInterval

  // if param is tab=credit-packs, set pricingInterval to packs
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const tab = query.get('tab')
    if (tab === 'credit-packs' && isSubscribed) {
      setPricingInterval('packs')
    }
  }, [isSubscribed])

  window.intercomSettings = {
    alignment: 'right',
    vertical_padding: 90,
    horizontal_padding: 0,
  }

  const updateUserInDb = useCallback(
    async (data) => {
      try {
        await http.patch('/api/auth/me', data)

        setCurrentUser((currentUser) => ({
          ...currentUser,
          ...data,
        }))
      } catch (err) {
        logtail.info(
          `${currentUser?.distinctId} felog Pricing.js updateUserInDb error`,
          { user: currentUser, error: err },
        )
        logtail.flush()
      }
    },
    [currentUser, setCurrentUser],
  )

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const interval = query.get('interval')
    const paymentGatewayToUse = query.get('payment')

    // eslint-disable-next-line eqeqeq
    if (
      interval != null &&
      // eslint-disable-next-line eqeqeq
      interval !== '' &&
      // eslint-disable-next-line eqeqeq
      interval != undefined &&
      (interval === 'monthly' || interval === 'yearly')
    ) {
      setPricingInterval(interval)
    }

    if (
      !currentUser?.paymentGateway &&
      !(
        currentUser?.subscriptionStatus === 'active' ||
        currentUser?.subscriptionStatus === 'trialing' ||
        currentUser?.subscriptionStatus === 'past_due'
      )
    ) {
      // only update usePaymentGateway if user is not subscribed
      if (paymentGatewayToUse === 'stripe') {
        updateUserInDb({ usePaymentGateway: 'stripe' })
      } else if (paymentGatewayToUse === 'paddle') {
        updateUserInDb({ usePaymentGateway: 'paddle' })
      }
    }
  }, [currentUser, setCurrentUser, updateUserInDb])

  useEffect(() => {
    window.addEventListener('paddleCheckoutClosed', async (data) => {
      logtail.info(
        `${currentUser?.distinctId} felog Pricing.js addEventListener('paddleCheckoutClosed') Paddle iframe closed data`,
        { paddle: data },
      )
      logtail.flush()

      handleOnClose()
    })

    window.addEventListener('paddleCheckoutCompleted', async (data) => {
      try {
        setIsSubmitting(true)
        let checkoutData = data.detail

        logtail.info(
          `${currentUser?.distinctId} felog Pricing.js addEventListener('paddleCheckoutCompleted') Paddle iframe checkoutData`,
          { paddle: checkoutData },
        )
        logtail.flush()

        // store data into tempUser collection, route is /api/auth/paddle/checkout-completed
        http.post('/api/auth/paddle/checkout-completed', checkoutData)

        // confirm paddle transaction status
        if (checkoutData?.data?.transaction_id) {
          await http.post('/api/auth/paddle/confirm-transaction', {
            transactionId: checkoutData?.data?.transaction_id,
          })

          const response = await getCurrentUser()

          handleOnClose()
          setCurrentUser((currentUser) => ({
            ...currentUser,
            ...response.data.user,
          }))
          // close loading modal
          setIsSubmitting(false)
        } else {
          throw new Error('There was an error processing your request.')
        }
      } catch (err) {
        logtail.info(
          `${currentUser?.distinctId} felog Pricing.js addEventListener('paddleCheckoutCompleted') error`,
          { user: currentUser, error: err },
        )
        logtail.flush()

        handleOnClose()
        if (err.response) {
          toast.error(
            err.response.data?.message ||
              err.response.data?.error ||
              err.response.data,
          )
        } else {
          toast.error(err.message)
        }
      }
    })
  }, [])

  const openModal = async (_amount = 0, tier = null) => {
    if (
      currentUser?.paymentGateway === 'apple' ||
      currentUser?.paymentGateway === 'google'
    ) {
      toast.error('Please manage your subscription on the Kaiber mobile app.')
      return
    }

    if (!isAuthenticated) {
      window.location.href = '/login?redirect=/pricing'
      return
    }

    if (pricingInterval === 'packs') {
      setAmount(_amount)
    } else {
      setSelectedTier({
        id: tier.paddlePricingId,
        name: `${tier.name.toLowerCase()}_tier_${pricingInterval}`,
      })
    }

    if (currentUser?.subscriptionCancelAtPeriodEndDate) {
      setIsSubmitting(true)
      const response = await http.post(`/api/auth/myaccount/subscription/renew`)
      logtail.info(
        `${currentUser?.distinctId} felog Pricing.js handleSubmit() response from /api/auth/myaccount/subscription/renew`,
        { response: response },
      )
      logtail.flush()

      const userResponse = await getCurrentUser()

      setCurrentUser((currentUser) => ({
        ...currentUser,
        ...userResponse.data.user,
      }))

      toast.success('Your subscription has been reactivated.')
      setIsSubmitting(false)
      setIsOpen(true)
    } else {
      setIsOpen(!isOpen)
    }
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const handleOnOpen = () => {
    setIsOpen(true)
  }

  const tiers = [
    {
      name: 'Explorer',
      href: '#',
      priceMonthly: 5,
      priceYearly: null,
      description: 'Unlock all that Kaiber has to offer',
      buttonText:
        pricingInterval === 'yearly' ? 'Monthly plan only' : 'Subscribe now',
      disabled: pricingInterval === 'yearly',
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Explorer'),
      includedFeatures: [
        !currentUser?.freeTrialClaimed
          ? {
              title: `7-day free trial, ${CREDITS.FREE_TRIAL} credits`,
              tooltip:
                'Automatically starts the Explorer subscription after the trial ends. You can choose a different plan to launch at anytime during trial, you can also opt to skip the trial.',
            }
          : null,
        {
          title: '$5 billed monthly for 300 credits',
          tooltip: 'This will automatically renew each month',
        },
        {
          title: 'Flipbook: up to 1 min videos',
          tooltip: '1 second of Flipbook generation = 1 credit',
        },
        {
          title: 'Motion 2.0: up to 16 sec videos',
          tooltip: '1 second of Motion 2.0 generation = 4 credits',
        },
        {
          title: 'Motion 3.0: up to 16 sec videos',
          tooltip: '1 second of Motion 3.0 generation = 5 credits',
        },
        {
          title: 'Transform 1.0: up to 1 min videos',
          tooltip: '1 second of Transform 1.0 generation = 5 credits',
        },
        {
          title: 'Transform 3.0: up to 1 min videos',
          tooltip: '1 second of Transform 3.0 generation = 6 credits',
        },
        {
          title: 'Gallery',
        },
        {
          title: 'Audioreactivity',
          tooltip:
            'Up to 1 minute with Flipbook \\ Up to 4 minutes with Motion 3.0 Only / File types: MP3 & WAV up to 50mb',
        },
        {
          title: 'Motion Mask',
          tooltip: 'Up to 16 sec videos with Motion 3.0 only',
        },
        {
          title: 'Animation (start with a prompt)',
        },
        {
          title: 'Transform (video-to-video)',
          tooltip:
            'Aspect ratio will match the uploaded video / File types MPG & MOV up to 200mb',
        },
        {
          title: 'Image-to-Video (start with an image)',
          tooltip:
            'Aspect ratio will match the uploaded image / File types: PNG & JPG up to 20mb',
        },
        {
          title: 'Select camera movements',
          tooltip: 'This applies to Flipbook only',
        },
        {
          title: '+Scenes',
          tooltip:
            'This is available for Flipbook, Motion 2.0, and Transform 1.0 only. Create up to 10 scenes',
        },
        {
          title: 'Any aspect ratio',
          tooltip:
            'This is available for Flipbook and Motion only. Uploading media use the file Aspect Ratio',
        },
      ],
      paddlePricingId: currentUser?.freeTrialClaimed
        ? process.env.REACT_APP_PADDLE_EXPLORER_MONTHLY_PLAN
        : process.env.REACT_APP_PADDLE_EXPLORER_MONTHLY_PLAN_FREE_TRIAL,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
    {
      name: 'Pro',
      href: '#',
      priceMonthly: 15,
      priceYearly: 120,
      description: 'More credits for committed creators',
      buttonText: 'Subscribe now',
      disabled: false,
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Pro'),
      includedFeatures: [
        {
          title:
            pricingInterval === 'yearly'
              ? '$120 billed annually for 12,000 credits'
              : '$15 billed monthly for 1,000 credits',
          tooltip:
            pricingInterval === 'yearly'
              ? 'This will automatically renew each year'
              : 'This will automatically renew each month',
        },
        {
          title: 'Discounted credit packs',
        },
        {
          title: 'Flipbook: up to 8 min videos',
          tooltip: '1 second of Flipbook generation = 1 credit',
        },
        {
          title: 'Motion 2.0: up to 160 sec videos',
          tooltip: '1 second of Motion 2.0 generation = 4 credits',
        },
        {
          title: 'Motion 3.0: up to 16 sec videos',
          tooltip: '1 second of Motion 3.0 generation = 5 credits',
        },
        {
          title: 'Transform 1.0: up to 4 min videos',
          tooltip: '1 second of Transform 1.0 generation = 5 credits',
        },
        {
          title: 'Transform 3.0: up to 4 min videos',
          tooltip: '1 second of Transform 3.0 generation = 6 credits',
        },
        {
          title: 'Gallery',
        },
        {
          title: 'Audioreactivity',
          tooltip:
            'Up to 8 minutes with Flipbook \\ Up to 4 minutes with Motion 3.0 only / File types: MP3 & WAV up to 50mb',
        },
        {
          title: 'Motion Mask',
          tooltip: 'Up to 16 sec videos with Motion 3.0 only',
        },
        {
          title: 'Animation (start with a prompt)',
        },
        {
          title: 'Transform (video-to-video)',
          tooltip:
            'Aspect ratio will match the uploaded video / File types MPG & MOV up to 200mb',
        },
        {
          title: 'Image-to-Video (start with an image)',
          tooltip:
            'Aspect ratio will match the uploaded image / File types: PNG & JPG up to 20mb',
        },
        {
          title: 'Select camera movements',
          tooltip: 'This applies to Flipbook only',
        },
        {
          title: '+Scenes',
          tooltip:
            'This is available for Flipbook, Motion 2.0, and Transform 1.0 only. Create up to 10 scenes',
        },
        {
          title: 'Any aspect ratio',
          tooltip:
            'This is available for Flipbook and Motion only. Uploading media use the file Aspect Ratio',
        },
        {
          title: 'Upscale 2x & 4x',
          tooltip:
            '2x = 1 credit for every 10 seconds, 4x = 1 credit for every 5 seconds.',
        },
      ],
      paddlePricingId:
        pricingInterval === 'yearly'
          ? process.env.REACT_APP_PADDLE_PRO_YEARLY_PLAN
          : process.env.REACT_APP_PADDLE_PRO_MONTHLY_PLAN,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
    {
      name: 'Artist',
      href: '#',
      priceMonthly: 30,
      priceYearly: 300,
      description: 'For power users who want the most value',
      buttonText: 'Subscribe now',
      disabled: false,
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Artist'),
      includedFeatures: [
        {
          title:
            pricingInterval === 'yearly'
              ? '$300 billed annually for 33,333 credits'
              : '$30 billed monthly for 2,500 credits',
          tooltip:
            pricingInterval === 'yearly'
              ? 'This will automatically renew each year'
              : 'This will automatically renew each month',
        },
        {
          title: 'Discounted credit packs',
          tooltip: '',
        },
        {
          title: 'Beta access to test drive new Kaiber features',
          tooltip: '',
        },
        {
          title: 'Flipbook: up to 8 min videos',
          tooltip: '1 second of Flipbook generation = 1 credit',
        },
        {
          title: 'Motion 2.0: up to 160 sec videos',
          tooltip: '1 second of Motion 2.0 generation = 4 credits',
        },
        {
          title: 'Motion 3.0: up to 16 sec videos',
          tooltip: '1 second of Motion 3.0 generation = 5 credits',
        },
        {
          title: 'Transform 1.0: up to 4 min videos',
          tooltip: '1 second of Transform 1.0 generation = 5 credits',
        },
        {
          title: 'Transform 3.0: up to 4 min videos',
          tooltip: '1 second of Transform 3.0 generation = 6 credits',
        },
        {
          title: 'Motion Mask',
          tooltip: 'Up to 16 sec videos with Motion 3.0 only',
        },
        {
          title: 'Gallery',
          tooltip: '',
        },
        {
          title: 'Audioreactivity',
          tooltip:
            'Up to 8 minutes with Flipbook \\ Up to 4 minutes with Motion 3.0 only / File types: MP3 & WAV up to 50mb',
        },
        {
          title: 'Animation (start with a prompt)',
          tooltip: '',
        },
        {
          title: 'Transform (video-to-video)',
          tooltip:
            'Aspect ratio will match the uploaded video / File types MPG & MOV up to 200mb',
        },
        {
          title: 'Image-to-Video (start with an image)',
          tooltip:
            'Aspect ratio will match the uploaded image / File types: PNG & JPG up to 20mb',
        },
        {
          title: 'Select camera movements',
          tooltip: 'This applies to Flipbook only',
        },
        {
          title: '+Scenes',
          tooltip:
            'This is available for Flipbook, Motion 2.0, and Transform 1.0 only. Create up to 10 scenes',
        },
        {
          title: 'Any aspect ratio',
          tooltip:
            'This is available for Flipbook and Motion only. Uploading media use the file Aspect Ratio',
        },
        {
          title: 'Upscale 2x & 4x',
          tooltip:
            '2x = 1 credit for every 10 seconds, 4x = 1 credit for every 5 seconds.',
        },
      ],
      paddlePricingId:
        pricingInterval === 'yearly'
          ? process.env.REACT_APP_PADDLE_ARTIST_YEARLY_PLAN
          : process.env.REACT_APP_PADDLE_ARTIST_MONTHLY_PLAN,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
  ]
  const packs = [
    {
      name: 'Pack 1',
      id: 1,
      href: '#',
      price: 15,
      amount: 1000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['1,000 credits'],
      paddlePricingId: process.env.REACT_APP_PADDLE_PACK_1_PRICE,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 2',
      id: 2,
      href: '#',
      price: 65,
      amount: 5000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['Save up to 13.3%', '5,000 credits'],
      paddlePricingId: process.env.REACT_APP_PADDLE_PACK_2_PRICE,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 3',
      id: 3,
      href: '#',
      price: 120,
      amount: 10000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['Save up to 20%', '10,000 credits'],
      paddlePricingId: process.env.REACT_APP_PADDLE_PACK_3_PRICE,
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
  ]

  const PricingPageButton = ({ id, text, action, isDisabled }) => {
    return (
      <button
        id={!isDisabled ? id : null}
        type='button'
        className={
          isDisabled
            ? 'uppercase mt-8 block w-full rounded-full border border-gray-800 bg-gray-100/50 py-2 text-center text-sm font-semibold text-gray-900'
            : 'uppercase mt-8 block w-full rounded-full border border-gray-800 radial-gradient button-shadow py-2 text-center text-sm font-semibold text-gray-900 hover:bg-white'
        }
        onClick={() => action()}
        disabled={isDisabled}
      >
        {text}
      </button>
    )
  }

  const isCurrentPlan = (tier) => {
    return (
      (tier.isCurrentPlan && tier.name === 'Free') ||
      (tier.isCurrentPlan &&
        pricingInterval === subscriptionInterval + 'ly' &&
        currentUser?.subscriptionStatus !== 'trialing')
    )
  }

  const pricingPageButtonAction = (tier) => {
    if (
      currentUser?.paymentGateway === 'stripe' &&
      isCurrentPlan(tier) &&
      currentUser?.subscriptionCancelAtPeriodEndDate
    ) {
      window.location.href = process.env.REACT_APP_STRIPE_BILLING_URL
    } else {
      tier.buttonAction(null, tier)
    }
  }

  const formatDate = (unix) => {
    return moment(unix * 1000).format('LL')
  }

  const imageO = '/website/welcome/img_o.png'
  const imageD = '/website/welcome/img_d.png'
  const imageK = '/website/welcome/img_k.png'
  const item1Img =
    'https://website-public-assets.kybercorp.org/web/pricing/entreprise.png'
  const item2Img =
    'https://website-public-assets.kybercorp.org/web/pricing/pricing2.png'

  return (
    <div className='bg-black'>
      <Navbar />

      <Modal
        open={isSubmitting}
        setOpen={isSubmitting ? () => {} : handleOnClose}
        persistent={isSubmitting}
      >
        <div className='w-full px-6 py-6 space-y-8 sm:px-12 sm:py-10'>
          <div className='flex flex-col items-center justify-end gap-2 mt-4'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      </Modal>

      <div className='flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 py-32 sm:pt-52 gap-24'>
        {/* TOP SECTION */}
        <div className='relative flex flex-col items-center justify-end sm:flex-row gap-14'>
          <div className='flex justify-center order-last w-full max-w-sm min-w-full gap-4 lg:max-w-4xl md:max-w-xl sm:gap-10 sm:order-first'>
            <div className='flex flex-col items-center gap-4 h-fit'>
              <img
                className='max-h-[435px] flex-grow'
                src={imageO}
                alt='Kaiber ODK'
              />
            </div>
            <div className='flex flex-col items-center gap-4 h-fit'>
              <img
                className='max-h-[435px] flex-grow'
                src={imageD}
                alt='Kaiber ODK'
              />
            </div>
            <div className='flex flex-col items-center gap-4 h-fit'>
              <img
                className='max-h-[432px] flex-grow'
                src={imageK}
                alt='Kaiber ODK'
              />
            </div>
          </div>

          <h1 className='flex order-first w-full text-4xl font-semibold text-white sm:w-1/2 sm:min-w-max lg:text-6xl sm:text-5xl sm:order-last sm:whitespace-pre-line'>
            Limitless{'\n'} creativity, one click{'\n'} away
          </h1>
        </div>

        {/* PRICING TABLE */}
        <div className='w-full px-4 py-12 mx-auto sm:py-24 sm:px-6 lg:px-8'>
          <div className='flex flex-col '>
            {isSubscribed && currentUser?.subscriptionStatus === 'past_due' && (
              <div className='max-w-[700px] mx-auto rounded-md bg-gray-800 text-left text-sm px-4 py-2.5 mb-6 sm:mb-8'>
                <ExclamationCircleIcon className='inline w-6 h-6 mr-2 text-red-400' />
                <p className='inline text-red-400'>
                  We apologize for the inconvenience, but it appears that your
                  recent payment attempt was unsuccessful. To prevent any
                  disruptions to your subscription service, we kindly ask you to
                  update your card information by{' '}
                  <a
                    href={process.env.REACT_APP_STRIPE_BILLING_URL}
                    className='font-bold underline'
                  >
                    clicking here
                  </a>
                  .
                </p>
              </div>
            )}
            <div className='flex flex-col sm:flex-row sm:gap-20'>
              <h1 className='text-4xl font-semibold tracking-tight text-white lg:text-6xl md:text-5xl '>
                Pricing plans
              </h1>
              <div className='relative flex flex-wrap gap-3 mt-5 rounded-lg w-fit sm:mt-0 sm:gap-5'>
                <button
                  type='button'
                  className={
                    pricingInterval === 'monthly'
                      ? 'relative whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                      : 'relative whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                  }
                  onClick={() => setPricingInterval('monthly')}
                >
                  Monthly
                </button>
                <button
                  type='button'
                  className={
                    pricingInterval === 'yearly'
                      ? 'relative whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                      : 'relative whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                  }
                  onClick={() => setPricingInterval('yearly')}
                >
                  Annual
                </button>
                {isSubscribed && (
                  <button
                    type='button'
                    className={
                      pricingInterval === 'packs'
                        ? 'relative sm:whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                        : 'relative sm:whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                    }
                    onClick={() => setPricingInterval('packs')}
                  >
                    Credit Packs
                  </button>
                )}
              </div>
            </div>
            <p className='mt-5 text-white/50 w-fit'>
              {pricingInterval !== 'packs'
                ? 'Save up to 33% when you pay annually'
                : 'Available to subscribed users only'}
            </p>
            <p className='mt-2 text-white/50 w-fit'>
              All taxes are calculated at checkout. Price excludes tax.
            </p>
          </div>

          <div
            className={`mt-12 sm:mt-16 grid sm:grid-cols-2 xl:grid-cols-3 sm:space-y-0 gap-6`}
          >
            {pricingInterval !== 'packs'
              ? tiers.map((tier, index) => (
                  <div
                    key={tier.name}
                    className={` divide-y divide-gray-200 rounded-3xl border border-[#CEFFCC]/10 ${
                      index === 2 ? ' dark-gradial-shadow' : ''
                    } ${
                      tier.disabled ? ' opacity-50 pointer-events-none' : ''
                    } `}
                  >
                    <div className='flex flex-col justify-between h-full p-6'>
                      <div>
                        <h2 className='text-lg font-medium leading-6 text-white'>
                          {tier.name}
                        </h2>
                        <p className='mt-4 text-sm text-gray-300'>
                          {tier.description}
                        </p>
                        {pricingInterval === 'monthly' ? (
                          <p className='mt-8'>
                            <span className='text-4xl font-bold tracking-tight text-white'>
                              ${tier.priceMonthly}
                            </span>{' '}
                            <span className='text-base font-medium text-gray-300'>
                              /mo
                            </span>
                          </p>
                        ) : (
                          <p className='mt-8'>
                            <span className='text-4xl font-bold tracking-tight text-white'>
                              $
                              {tier.priceYearly != null
                                ? tier.priceYearly / 12
                                : tier.priceMonthly}
                            </span>{' '}
                            <span className='text-base font-medium text-gray-300'>
                              /mo
                            </span>
                          </p>
                        )}
                        {pricingInterval === 'yearly' ? (
                          <p className='mt-4 text-sm text-gray-300'>
                            {tier.yearlySubtext}
                          </p>
                        ) : null}

                        <ul className='mt-6 space-y-4'>
                          {tier.includedFeatures
                            .filter(Boolean)
                            .map((feature) => (
                              // feature: { title: 'string', tooltip: 'string' }
                              <li
                                key={feature.title}
                                className='grid grid-cols-[auto_1fr_auto] items-start gap-3'
                              >
                                <CheckIcon
                                  className={`h-5 w-5 flex-shrink-0${
                                    tier.disabled
                                      ? ' text-gray-500'
                                      : ' text-green-500'
                                  }`}
                                  aria-hidden='true'
                                />
                                <div>
                                  <span className='text-sm text-gray-300'>
                                    {feature.title}
                                  </span>
                                </div>
                                {feature.tooltip ? (
                                  <Tooltip content={feature.tooltip}>
                                    <span className='text-white w-fit'>
                                      <img
                                        src={InfoIcon}
                                        className='w-5 h-5'
                                        alt='info'
                                      />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <div />
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>

                      {currentUser?.subscriptionCancelAtPeriodEndDate &&
                        isCurrentPlan(tier) && (
                          <p className='mt-4 text-tertiary/80'>
                            <ExclamationTriangleIcon className='float-left w-5 h-5 mt-1 text-orange-500' />
                            <span className='block ml-8'>
                              Your plan will be canceled at{' '}
                              {formatDate(currentUser.periodEndDate)}.
                            </span>
                          </p>
                        )}

                      {/* pricing page button defaulted isDisabled to false, revise logic */}
                      <PricingPageButton
                        id={`pricing-${tier.name.toLowerCase()}-${pricingInterval}`}
                        text={
                          isCurrentPlan(tier)
                            ? currentUser?.subscriptionCancelAtPeriodEndDate
                              ? 'Renew Plan'
                              : 'Current Plan'
                            : currentUser?.subscriptionCancelAtPeriodEndDate
                              ? 'Renew & Update Plan'
                              : tier.buttonText
                        }
                        action={() => pricingPageButtonAction(tier)}
                        isDisabled={
                          tier.disabled ||
                          (isCurrentPlan(tier) &&
                            !currentUser?.subscriptionCancelAtPeriodEndDate) ||
                          (tier.name === 'Free' &&
                            currentUser?.subscriptionCancelAtPeriodEndDate) ||
                          (isSubscribed &&
                            currentUser?.subscriptionStatus === 'past_due')
                        }
                      />
                    </div>
                  </div>
                ))
              : null}
            {pricingInterval === 'packs' &&
            currentUser?.subscriptionStatus !== 'trialing'
              ? packs.map((pack, index) => (
                  <div
                    key={index}
                    className={classNames(
                      'divide-y divide-gray-200 rounded-3xl border border-[#CEFFCC]/10',
                      pack?.price > 0 && 'dark-gradial-shadow',
                    )}
                  >
                    <div className='flex flex-col justify-between h-full p-6'>
                      <div>
                        <h2 className='text-lg font-medium leading-6 text-white'>
                          {pack.name}
                        </h2>
                        <p className='mt-4 text-sm text-gray-300'>
                          {pack.description}
                        </p>
                        <p className='mt-8'>
                          <span className='text-4xl font-bold tracking-tight text-white'>
                            ${pack.price}
                          </span>
                        </p>
                      </div>
                      <ul className='mt-6 space-y-4'>
                        {pack.includedFeatures.map((feature) => (
                          <li key={feature} className='flex space-x-3'>
                            <CheckIcon
                              className='flex-shrink-0 w-5 h-5 text-green-500'
                              aria-hidden='true'
                            />
                            <span className='text-sm text-gray-300'>
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <PricingPageButton
                        text={
                          isCurrentPlan(pack) ? 'Current Plan' : pack.buttonText
                        }
                        action={() => {
                          pack.buttonAction(pack)
                        }}
                        isDisabled={isCurrentPlan(pack)}
                      />
                    </div>
                  </div>
                ))
              : null}
            {/* remove interval
            {pricingInterval !== 'packs' && (
              <div className='divide-y divide-gray-200 rounded-3xl border border-[#CEFFCC]/10 shadow-sm order-4'>
                <div className='flex flex-col justify-between h-full p-6'>
                  <div className=''>
                    <h2 className='text-lg font-medium leading-6 text-white'>
                      Enterprise
                    </h2>
                    <p className='mt-8'>
                      <span className='text-4xl font-bold tracking-tight text-white'>
                        Get a Quote
                      </span>
                    </p>
                    <p className='mt-4 text-sm text-gray-300'>
                      Creating a long-form video? Want hands-on support from an AI
                      artist? Get in touch to find a solution that works for you.
                    </p>
                  </div>
                  <div>
                    <a
                      href='mailto:support@kaiber.ai?subject=Kaiber Inquiry'
                      className='block w-full py-2 mt-8 text-sm font-semibold text-center text-gray-900 uppercase border border-gray-800 rounded-full radial-gradient button-shadow hover:bg-white'
                    >
                      Talk To Us
                    </a>
                  </div>
                </div>
              </div>
            )}
            */}
          </div>
        </div>

        {/* BOTTOM SECTION */}
        <PricingBottomItem
          title={'Enterprise'}
          description={'For teams and companies staying ahead of the curve.'}
          img={item1Img}
          size={'sm:max-w-[35%]'}
          btnText={'Contact Us'}
          btnUrl='mailto:hello@kaiber.ai'
        />

        <PricingBottomItem
          title={'50% off for musicians'}
          description={
            'Music and visuals are better together. Kaiber is excited to support musicians with specialized pricing to help bring your art to life.'
          }
          img={item2Img}
          size={'sm:max-w-[50%]'}
          btnText={'Get started'}
          btnUrl='/musician'
          orderTxt='sm:order-last'
          orderImg='sm:order-first'
        />
      </div>
      <Footer />
      {pricingInterval === 'packs' ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.pack
          isOpen={isOpen}
          onClose={handleOnClose}
          pack={amount}
        />
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.subscribe
          isOpen={isOpen}
          onClose={handleOnClose}
          onOpen={handleOnOpen}
          tier={selectedTier}
        />
      )}
    </div>
  )
}
